import React from 'react'

import { titleIfy, slugify } from '../../utils/helpers'
import { Link } from "gatsby"
import { GiSofa } from 'react-icons/gi'

import { SiteContext, ContextProviderComponent } from '../context/mainContext'

class Nav extends React.Component {
  render() {
    let { navItems: { navInfo: { data: links } } } = this.props.context

    let pagePath = '/';
    // to satisfy headless rendering
    if (typeof window !== `undefined`) {
      pagePath = (window.location || {}).pathname.replace('/', '');
    }

    const adhocPages = ['/', 'shopnow', 'cart', 'checkout'];
    const navigationWorksOnPages = [...links, ...adhocPages].map(slugify);

    links = links.map(link => {
      const newLink = {}
      newLink.link = pagePath === '' || navigationWorksOnPages.filter(e => e === pagePath).length > 0 ? slugify(link) : slugify('/');
      newLink.name = titleIfy(link)
      return newLink
    })
    links.unshift({ name: 'Home', link: '' })
    return (
      <>
        <div className="w-10/12 flex flex-wrap items-center">
          <Link to="/" className="px-5 visible hidden sm:block">
            <GiSofa size={36} />
          </Link>
          {
            links.map((l, i) => (
              <Link to={"/" + l.link} key={i}>
                <p key={i} className="text-left m-0 text-smaller mr-4 sm:mr-8 font-semibold">{l.name}</p>
              </Link>
            ))
          }
        </div>
      </>
    )
  }
}

function NavWithContext(props) {
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {
          context => <Nav {...props} context={context} />
        }
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}

export default NavWithContext
